export const countries = [
  { value: 'Afghanistan', text: 'Afghanistan' },
  { value: 'Albania', text: 'Albania' },
  { value: 'Algeria', text: 'Algeria' },
  { value: 'Andorra', text: 'Andorra' },
  { value: 'Angola', text: 'Angola' },
  { value: 'Antigua and Barbuda', text: 'Antigua and Barbuda' },
  { value: 'Argentina', text: 'Argentina' },
  { value: 'Armenia', text: 'Armenia' },
  { value: 'Australia', text: 'Australia' },
  { value: 'Austria', text: 'Austria' },
  { value: 'Azerbaijan', text: 'Azerbaijan' },
  { value: 'Bahamas', text: 'Bahamas' },
  { value: 'Bahrain', text: 'Bahrain' },
  { value: 'Bangladesh', text: 'Bangladesh' },
  { value: 'Barbados', text: 'Barbados' },
  { value: 'Belarus', text: 'Belarus' },
  { value: 'Belgium', text: 'Belgium' },
  { value: 'Belize', text: 'Belize' },
  { value: 'Benin', text: 'Benin' },
  { value: 'Bhutan', text: 'Bhutan' },
  { value: 'Bolivia', text: 'Bolivia' },
  { value: 'Bosnia and Herzegovina', text: 'Bosnia and Herzegovina' },
  { value: 'Botswana', text: 'Botswana' },
  { value: 'Brazil', text: 'Brazil' },
  { value: 'Brunei Darussalam', text: 'Brunei Darussalam' },
  { value: 'Bulgaria', text: 'Bulgaria' },
  { value: 'Burkina Faso', text: 'Burkina Faso' },
  { value: 'Burundi', text: 'Burundi' },
  { value: 'Cambodia', text: 'Cambodia' },
  { value: 'Cameroon', text: 'Cameroon' },
  { value: 'Canada', text: 'Canada' },
  { value: 'Cape Verde', text: 'Cape Verde' },
  { value: 'Central African Republic', text: 'Central African Republic' },
  { value: 'Chad', text: 'Chad' },
  { value: 'Chile', text: 'Chile' },
  { value: 'China', text: 'China' },
  { value: 'Colombia', text: 'Colombia' },
  { value: 'Comoros', text: 'Comoros' },
  { value: 'Congo (Republic of the)', text: 'Congo (Republic of the)' },
  { value: 'Costa Rica', text: 'Costa Rica' },
  { value: 'Côte d’Ivoire', text: 'Côte d’Ivoire' },
  { value: 'Croatia', text: 'Croatia' },
  { value: 'Cuba', text: 'Cuba' },
  { value: 'Cyprus', text: 'Cyprus' },
  { value: 'Czech Republic', text: 'Czech Republic' },
  {
    value: 'Democratic People’s Republic of Korea',
    text: 'Democratic People’s Republic of Korea'
  },
  {
    value: 'Democratic Republic of the Congo',
    text: 'Democratic Republic of the Congo'
  },
  { value: 'Denmark', text: 'Denmark' },
  { value: 'Djibouti', text: 'Djibouti' },
  { value: 'Dominica', text: 'Dominica' },
  { value: 'Dominican Republic', text: 'Dominican Republic' },
  { value: 'Ecuador', text: 'Ecuador' },
  { value: 'Egypt', text: 'Egypt' },
  { value: 'El Salvador', text: 'El Salvador' },
  { value: 'Equatorial Guinea', text: 'Equatorial Guinea' },
  { value: 'Eritrea', text: 'Eritrea' },
  { value: 'Estonia', text: 'Estonia' },
  { value: 'Ethiopia', text: 'Ethiopia' },
  { value: 'Fiji', text: 'Fiji' },
  { value: 'Finland', text: 'Finland' },
  { value: 'France', text: 'France' },
  { value: 'Gabon', text: 'Gabon' },
  { value: 'Gambia', text: 'Gambia' },
  { value: 'Georgia', text: 'Georgia' },
  { value: 'Germany', text: 'Germany' },
  { value: 'Greece', text: 'Greece' },
  { value: 'Grenada', text: 'Grenada' },
  { value: 'Guatemala', text: 'Guatemala' },
  { value: 'Guinea', text: 'Guinea' },
  { value: 'Guinea-Bissau', text: 'Guinea-Bissau' },
  { value: 'Guyana', text: 'Guyana' },
  { value: 'Haiti', text: 'Haiti' },
  { value: 'Honduras', text: 'Honduras' },
  { value: 'Hungary', text: 'Hungary' },
  { value: 'Iceland', text: 'Iceland' },
  { value: 'India', text: 'India' },
  { value: 'Indonesia', text: 'Indonesia' },
  { value: 'Iran', text: 'Iran' },
  { value: 'Iraq', text: 'Iraq' },
  { value: 'Ireland', text: 'Ireland' },
  { value: 'Israel', text: 'Israel' },
  { value: 'Italy', text: 'Italy' },
  { value: 'Jamaica', text: 'Jamaica' },
  { value: 'Japan', text: 'Japan' },
  { value: 'Jordan', text: 'Jordan' },
  { value: 'Kazakhstan', text: 'Kazakhstan' },
  { value: 'Kenya', text: 'Kenya' },
  { value: 'Kiribati', text: 'Kiribati' },
  { value: 'Kuwait', text: 'Kuwait' },
  { value: 'Kyrgyzstan', text: 'Kyrgyzstan' },
  {
    value: 'Lao People’s Democratic Republic',
    text: 'Lao People’s Democratic Republic'
  },
  { value: 'Latvia', text: 'Latvia' },
  { value: 'Lebanon', text: 'Lebanon' },
  { value: 'Lesotho', text: 'Lesotho' },
  { value: 'Liberia', text: 'Liberia' },
  { value: 'Libya', text: 'Libya' },
  { value: 'Liechtenstein', text: 'Liechtenstein' },
  { value: 'Lithuania', text: 'Lithuania' },
  { value: 'Luxembourg', text: 'Luxembourg' },
  { value: 'Madagascar', text: 'Madagascar' },
  { value: 'Malawi', text: 'Malawi' },
  { value: 'Malaysia', text: 'Malaysia' },
  { value: 'Maldives', text: 'Maldives' },
  { value: 'Mali', text: 'Mali' },
  { value: 'Malta', text: 'Malta' },
  { value: 'Marshall Islands', text: 'Marshall Islands' },
  { value: 'Mauritania', text: 'Mauritania' },
  { value: 'Mauritius', text: 'Mauritius' },
  { value: 'Mexico', text: 'Mexico' },
  {
    value: 'Micronesia (Federated States of)',
    text: 'Micronesia (Federated States of)'
  },
  { value: 'Monaco', text: 'Monaco' },
  { value: 'Mongolia', text: 'Mongolia' },
  { value: 'Montenegro', text: 'Montenegro' },
  { value: 'Morocco', text: 'Morocco' },
  { value: 'Mozambique', text: 'Mozambique' },
  { value: 'Myanmar', text: 'Myanmar' },
  { value: 'Namibia', text: 'Namibia' },
  { value: 'Nauru', text: 'Nauru' },
  { value: 'Nepal', text: 'Nepal' },
  { value: 'Netherlands', text: 'Netherlands' },
  { value: 'New Zealand', text: 'New Zealand' },
  { value: 'Nicaragua', text: 'Nicaragua' },
  { value: 'Niger', text: 'Niger' },
  { value: 'Nigeria', text: 'Nigeria' },
  { value: 'Norway', text: 'Norway' },
  { value: 'Oman', text: 'Oman' },
  { value: 'Pakistan', text: 'Pakistan' },
  { value: 'Palau', text: 'Palau' },
  { value: 'Panama', text: 'Panama' },
  { value: 'Papua New Guinea', text: 'Papua New Guinea' },
  { value: 'Paraguay', text: 'Paraguay' },
  { value: 'Peru', text: 'Peru' },
  { value: 'Philippines', text: 'Philippines' },
  { value: 'Poland', text: 'Poland' },
  { value: 'Portugal', text: 'Portugal' },
  { value: 'Qatar', text: 'Qatar' },
  { value: 'Republic of Korea', text: 'Republic of Korea' },
  { value: 'Republic of Moldova', text: 'Republic of Moldova' },
  { value: 'Romania', text: 'Romania' },
  { value: 'Russian Federation', text: 'Russian Federation' },
  { value: 'Rwanda', text: 'Rwanda' },
  { value: 'Saint Kitts and Nevis', text: 'Saint Kitts and Nevis' },
  { value: 'Saint Lucia', text: 'Saint Lucia' },
  {
    value: 'Saint Vincent and the Grenadines',
    text: 'Saint Vincent and the Grenadines'
  },
  { value: 'Samoa', text: 'Samoa' },
  { value: 'San Marino', text: 'San Marino' },
  { value: 'Sao Tome and Principe', text: 'Sao Tome and Principe' },
  { value: 'Saudi Arabia', text: 'Saudi Arabia' },
  { value: 'Senegal', text: 'Senegal' },
  { value: 'Serbia', text: 'Serbia' },
  { value: 'Seychelles', text: 'Seychelles' },
  { value: 'Sierra Leone', text: 'Sierra Leone' },
  { value: 'Singapore', text: 'Singapore' },
  { value: 'Slovakia', text: 'Slovakia' },
  { value: 'Slovenia', text: 'Slovenia' },
  { value: 'Solomon Islands', text: 'Solomon Islands' },
  { value: 'Somalia', text: 'Somalia' },
  { value: 'South Africa', text: 'South Africa' },
  { value: 'South Sudan', text: 'South Sudan' },
  { value: 'Spain', text: 'Spain' },
  { value: 'Sri Lanka', text: 'Sri Lanka' },
  { value: 'Sudan', text: 'Sudan' },
  { value: 'Suriname', text: 'Suriname' },
  { value: 'Swaziland', text: 'Swaziland' },
  { value: 'Switzerland', text: 'Switzerland' },
  { value: 'Sweden', text: 'Sweden' },
  { value: 'Syria', text: 'Syria' },
  { value: 'Tajikistan', text: 'Tajikistan' },
  { value: 'Thailand', text: 'Thailand' },
  {
    value: 'The former Yugoslav Republic of Macedonia',
    text: 'The former Yugoslav Republic of Macedonia'
  },
  { value: 'Timor Leste', text: 'Timor Leste' },
  { value: 'Togo', text: 'Togo' },
  { value: 'Tonga', text: 'Tonga' },
  { value: 'Trinidad and Tobago', text: 'Trinidad and Tobago' },
  { value: 'Tunisia', text: 'Tunisia' },
  { value: 'Turkey', text: 'Turkey' },
  { value: 'Turkmenistan', text: 'Turkmenistan' },
  { value: 'Tuvalu', text: 'Tuvalu' },
  { value: 'Uganda', text: 'Uganda' },
  { value: 'Ukraine', text: 'Ukraine' },
  { value: 'United Arab Emirates', text: 'United Arab Emirates' },
  { value: 'United Kingdom', text: 'United Kingdom' },
  {
    value: 'United of Republic of Tanzania',
    text: 'United of Republic of Tanzania'
  },
  { value: 'United States', text: 'United States' },
  { value: 'Uruguay', text: 'Uruguay' },
  { value: 'Uzbekistan', text: 'Uzbekistan' },
  { value: 'Vanuatu', text: 'Vanuatu' },
  { value: 'Venezuela', text: 'Venezuela' },
  { value: 'Vietnam', text: 'Vietnam' },
  { value: 'Yemen', text: 'Yemen' },
  { value: 'Zambia', text: 'Zambia' },
  { value: 'Zimbabwe', text: 'Zimbabwe' }
];

export const states = [
  { value: 'AL', text: 'AL' },
  { value: 'AK', text: 'AK' },
  { value: 'AZ', text: 'AZ' },
  { value: 'AR', text: 'AR' },
  { value: 'CA', text: 'CA' },
  { value: 'CO', text: 'CO' },
  { value: 'CT', text: 'CT' },
  { value: 'DE', text: 'DE' },
  { value: 'FL', text: 'FL' },
  { value: 'GA', text: 'GA' },
  { value: 'HI', text: 'HI' },
  { value: 'ID', text: 'ID' },
  { value: 'IL', text: 'IL' },
  { value: 'IN', text: 'IN' },
  { value: 'IA', text: 'IA' },
  { value: 'KS', text: 'KS' },
  { value: 'KY', text: 'KY' },
  { value: 'LA', text: 'LA' },
  { value: 'ME', text: 'ME' },
  { value: 'MD', text: 'MD' },
  { value: 'MA', text: 'MA' },
  { value: 'MI', text: 'MI' },
  { value: 'MN', text: 'MN' },
  { value: 'MS', text: 'MS' },
  { value: 'MO', text: 'MO' },
  { value: 'MT', text: 'MT' },
  { value: 'NE', text: 'NE' },
  { value: 'NV', text: 'NV' },
  { value: 'NH', text: 'NH' },
  { value: 'NJ', text: 'NJ' },
  { value: 'NM', text: 'NM' },
  { value: 'NY', text: 'NY' },
  { value: 'NC', text: 'NC' },
  { value: 'ND', text: 'ND' },
  { value: 'OH', text: 'OH' },
  { value: 'OK', text: 'OK' },
  { value: 'OR', text: 'OR' },
  { value: 'PA', text: 'PA' },
  { value: 'RI', text: 'RI' },
  { value: 'SC', text: 'SC' },
  { value: 'SD', text: 'SD' },
  { value: 'TN', text: 'TN' },
  { value: 'TX', text: 'TX' },
  { value: 'UT', text: 'UT' },
  { value: 'VT', text: 'VT' },
  { value: 'VA', text: 'VA' },
  { value: 'WA', text: 'WA' },
  { value: 'WV', text: 'WV' },
  { value: 'WI', text: 'WI' },
  { value: 'WY', text: 'WY' }
];

export const countriesAlpha3Codes = [
  {
    value: 'AFG',
    text: 'Afghanistan'
  },
  {
    value: 'ALA',
    text: 'Åland Islands'
  },
  {
    value: 'ALB',
    text: 'Albania'
  },
  {
    value: 'DZA',
    text: 'Algeria'
  },
  {
    value: 'ASM',
    text: 'American Samoa'
  },
  {
    value: 'AND',
    text: 'Andorra'
  },
  {
    value: 'AGO',
    text: 'Angola'
  },
  {
    value: 'AIA',
    text: 'Anguilla'
  },
  {
    value: 'ATA',
    text: 'Antarctica'
  },
  {
    value: 'ATG',
    text: 'Antigua and Barbuda'
  },
  {
    value: 'ARG',
    text: 'Argentina'
  },
  {
    value: 'ARM',
    text: 'Armenia'
  },
  {
    value: 'ABW',
    text: 'Aruba'
  },
  {
    value: 'AUS',
    text: 'Australia'
  },
  {
    value: 'AUT',
    text: 'Austria',
    eu: true
  },
  {
    value: 'AZE',
    text: 'Azerbaijan'
  },
  {
    value: 'BHS',
    text: 'Bahamas'
  },
  {
    value: 'BHR',
    text: 'Bahrain'
  },
  {
    value: 'BGD',
    text: 'Bangladesh'
  },
  {
    value: 'BRB',
    text: 'Barbados'
  },
  {
    value: 'BLR',
    text: 'Belarus'
  },
  {
    value: 'BEL',
    text: 'Belgium',
    eu: true
  },
  {
    value: 'BLZ',
    text: 'Belize'
  },
  {
    value: 'BEN',
    text: 'Benin'
  },
  {
    value: 'BMU',
    text: 'Bermuda'
  },
  {
    value: 'BTN',
    text: 'Bhutan'
  },
  {
    value: 'BOL',
    text: 'Bolivia, Plurinational State of'
  },
  {
    value: 'BES',
    text: 'Bonaire, Sint Eustatius and Saba'
  },
  {
    value: 'BIH',
    text: 'Bosnia and Herzegovina'
  },
  {
    value: 'BWA',
    text: 'Botswana'
  },
  {
    value: 'BVT',
    text: 'Bouvet Island'
  },
  {
    value: 'BRA',
    text: 'Brazil'
  },
  {
    value: 'IOT',
    text: 'British Indian Ocean Territory'
  },
  {
    value: 'BRN',
    text: 'Brunei Darussalam'
  },
  {
    value: 'BGR',
    text: 'Bulgaria',
    eu: true
  },
  {
    value: 'BFA',
    text: 'Burkina Faso'
  },
  {
    value: 'BDI',
    text: 'Burundi'
  },
  {
    value: 'KHM',
    text: 'Cambodia'
  },
  {
    value: 'CMR',
    text: 'Cameroon'
  },
  {
    value: 'CAN',
    text: 'Canada'
  },
  {
    value: 'CPV',
    text: 'Cape Verde'
  },
  {
    value: 'CYM',
    text: 'Cayman Islands'
  },
  {
    value: 'CAF',
    text: 'Central African Republic'
  },
  {
    value: 'TCD',
    text: 'Chad'
  },
  {
    value: 'CHL',
    text: 'Chile'
  },
  {
    value: 'CHN',
    text: 'China'
  },
  {
    value: 'CXR',
    text: 'Christmas Island'
  },
  {
    value: 'CCK',
    text: 'Cocos (Keeling) Islands'
  },
  {
    value: 'COL',
    text: 'Colombia'
  },
  {
    value: 'COM',
    text: 'Comoros'
  },
  {
    value: 'COG',
    text: 'Congo'
  },
  {
    value: 'COD',
    text: 'Congo, the Democratic Republic of the'
  },
  {
    value: 'COK',
    text: 'Cook Islands'
  },
  {
    value: 'CRI',
    text: 'Costa Rica'
  },
  {
    value: 'CIV',
    text: "Côte d'Ivoire"
  },
  {
    value: 'HRV',
    text: 'Croatia',
    eu: true
  },
  {
    value: 'CUB',
    text: 'Cuba'
  },
  {
    value: 'CUW',
    text: 'Curaçao'
  },
  {
    value: 'CYP',
    text: 'Cyprus',
    eu: true
  },
  {
    value: 'CZE',
    text: 'Czech Republic',
    eu: true
  },
  {
    value: 'DNK',
    text: 'Denmark',
    eu: true
  },
  {
    value: 'DJI',
    text: 'Djibouti'
  },
  {
    value: 'DMA',
    text: 'Dominica'
  },
  {
    value: 'DOM',
    text: 'Dominican Republic'
  },
  {
    value: 'ECU',
    text: 'Ecuador'
  },
  {
    value: 'EGY',
    text: 'Egypt'
  },
  {
    value: 'SLV',
    text: 'El Salvador'
  },
  {
    value: 'GNQ',
    text: 'Equatorial Guinea'
  },
  {
    value: 'ERI',
    text: 'Eritrea'
  },
  {
    value: 'EST',
    text: 'Estonia',
    eu: true
  },
  {
    value: 'ETH',
    text: 'Ethiopia'
  },
  {
    value: 'FLK',
    text: 'Falkland Islands (Malvinas)'
  },
  {
    value: 'FRO',
    text: 'Faroe Islands'
  },
  {
    value: 'FJI',
    text: 'Fiji'
  },
  {
    value: 'FIN',
    text: 'Finland',
    eu: true
  },
  {
    value: 'FRA',
    text: 'France',
    eu: true
  },
  {
    value: 'GUF',
    text: 'French Guiana'
  },
  {
    value: 'PYF',
    text: 'French Polynesia'
  },
  {
    value: 'ATF',
    text: 'French Southern Territories'
  },
  {
    value: 'GAB',
    text: 'Gabon'
  },
  {
    value: 'GMB',
    text: 'Gambia'
  },
  {
    value: 'GEO',
    text: 'Georgia'
  },
  {
    value: 'DEU',
    text: 'Germany',
    eu: true
  },
  {
    value: 'GHA',
    text: 'Ghana'
  },
  {
    value: 'GIB',
    text: 'Gibraltar'
  },
  {
    value: 'GRC',
    text: 'Greece',
    eu: true
  },
  {
    value: 'GRL',
    text: 'Greenland'
  },
  {
    value: 'GRD',
    text: 'Grenada'
  },
  {
    value: 'GLP',
    text: 'Guadeloupe'
  },
  {
    value: 'GUM',
    text: 'Guam'
  },
  {
    value: 'GTM',
    text: 'Guatemala'
  },
  {
    value: 'GGY',
    text: 'Guernsey'
  },
  {
    value: 'GIN',
    text: 'Guinea'
  },
  {
    value: 'GNB',
    text: 'Guinea-Bissau'
  },
  {
    value: 'GUY',
    text: 'Guyana'
  },
  {
    value: 'HTI',
    text: 'Haiti'
  },
  {
    value: 'HMD',
    text: 'Heard Island and McDonald Islands'
  },
  {
    value: 'VAT',
    text: 'Holy See (Vatican City State)'
  },
  {
    value: 'HND',
    text: 'Honduras'
  },
  {
    value: 'HKG',
    text: 'Hong Kong'
  },
  {
    value: 'HUN',
    text: 'Hungary',
    eu: true
  },
  {
    value: 'ISL',
    text: 'Iceland'
  },
  {
    value: 'IND',
    text: 'India'
  },
  {
    value: 'IDN',
    text: 'Indonesia'
  },
  {
    value: 'IRN',
    text: 'Iran, Islamic Republic of'
  },
  {
    value: 'IRQ',
    text: 'Iraq'
  },
  {
    value: 'IRL',
    text: 'Ireland',
    eu: true
  },
  {
    value: 'IMN',
    text: 'Isle of Man'
  },
  {
    value: 'ISR',
    text: 'Israel'
  },
  {
    value: 'ITA',
    text: 'Italy',
    eu: true
  },
  {
    value: 'JAM',
    text: 'Jamaica'
  },
  {
    value: 'JPN',
    text: 'Japan'
  },
  {
    value: 'JEY',
    text: 'Jersey'
  },
  {
    value: 'JOR',
    text: 'Jordan'
  },
  {
    value: 'KAZ',
    text: 'Kazakhstan'
  },
  {
    value: 'KEN',
    text: 'Kenya'
  },
  {
    value: 'KIR',
    text: 'Kiribati'
  },
  {
    value: 'PRK',
    text: "Korea, Democratic People's Republic of"
  },
  {
    value: 'KOR',
    text: 'Korea, Republic of'
  },
  {
    value: 'KWT',
    text: 'Kuwait'
  },
  {
    value: 'KGZ',
    text: 'Kyrgyzstan'
  },
  {
    value: 'LAO',
    text: "Lao People's Democratic Republic"
  },
  {
    value: 'LVA',
    text: 'Latvia',
    eu: true
  },
  {
    value: 'LBN',
    text: 'Lebanon'
  },
  {
    value: 'LSO',
    text: 'Lesotho'
  },
  {
    value: 'LBR',
    text: 'Liberia'
  },
  {
    value: 'LBY',
    text: 'Libya'
  },
  {
    value: 'LIE',
    text: 'Liechtenstein'
  },
  {
    value: 'LTU',
    text: 'Lithuania',
    eu: true
  },
  {
    value: 'LUX',
    text: 'Luxembourg',
    eu: true
  },
  {
    value: 'MAC',
    text: 'Macao'
  },
  {
    value: 'MKD',
    text: 'Macedonia, the former Yugoslav Republic of'
  },
  {
    value: 'MDG',
    text: 'Madagascar'
  },
  {
    value: 'MWI',
    text: 'Malawi'
  },
  {
    value: 'MYS',
    text: 'Malaysia'
  },
  {
    value: 'MDV',
    text: 'Maldives'
  },
  {
    value: 'MLI',
    text: 'Mali'
  },
  {
    value: 'MLT',
    text: 'Malta',
    eu: true
  },
  {
    value: 'MHL',
    text: 'Marshall Islands'
  },
  {
    value: 'MTQ',
    text: 'Martinique'
  },
  {
    value: 'MRT',
    text: 'Mauritania'
  },
  {
    value: 'MUS',
    text: 'Mauritius'
  },
  {
    value: 'MYT',
    text: 'Mayotte'
  },
  {
    value: 'MEX',
    text: 'Mexico'
  },
  {
    value: 'FSM',
    text: 'Micronesia, Federated States of'
  },
  {
    value: 'MDA',
    text: 'Moldova, Republic of'
  },
  {
    value: 'MCO',
    text: 'Monaco'
  },
  {
    value: 'MNG',
    text: 'Mongolia'
  },
  {
    value: 'MNE',
    text: 'Montenegro'
  },
  {
    value: 'MSR',
    text: 'Montserrat'
  },
  {
    value: 'MAR',
    text: 'Morocco'
  },
  {
    value: 'MOZ',
    text: 'Mozambique'
  },
  {
    value: 'MMR',
    text: 'Myanmar'
  },
  {
    value: 'NAM',
    text: 'Namibia'
  },
  {
    value: 'NRU',
    text: 'Nauru'
  },
  {
    value: 'NPL',
    text: 'Nepal'
  },
  {
    value: 'NLD',
    text: 'Netherlands',
    eu: true
  },
  {
    value: 'NCL',
    text: 'New Caledonia'
  },
  {
    value: 'NZL',
    text: 'New Zealand'
  },
  {
    value: 'NIC',
    text: 'Nicaragua'
  },
  {
    value: 'NER',
    text: 'Niger'
  },
  {
    value: 'NGA',
    text: 'Nigeria'
  },
  {
    value: 'NIU',
    text: 'Niue'
  },
  {
    value: 'NFK',
    text: 'Norfolk Island'
  },
  {
    value: 'MNP',
    text: 'Northern Mariana Islands'
  },
  {
    value: 'NOR',
    text: 'Norway'
  },
  {
    value: 'OMN',
    text: 'Oman'
  },
  {
    value: 'PAK',
    text: 'Pakistan'
  },
  {
    value: 'PLW',
    text: 'Palau'
  },
  {
    value: 'PSE',
    text: 'Palestinian Territory, Occupied'
  },
  {
    value: 'PAN',
    text: 'Panama'
  },
  {
    value: 'PNG',
    text: 'Papua New Guinea'
  },
  {
    value: 'PRY',
    text: 'Paraguay'
  },
  {
    value: 'PER',
    text: 'Peru'
  },
  {
    value: 'PHL',
    text: 'Philippines'
  },
  {
    value: 'PCN',
    text: 'Pitcairn'
  },
  {
    value: 'POL',
    text: 'Poland',
    eu: true
  },
  {
    value: 'PRT',
    text: 'Portugal',
    eu: true
  },
  {
    value: 'PRI',
    text: 'Puerto Rico'
  },
  {
    value: 'QAT',
    text: 'Qatar'
  },
  {
    value: 'REU',
    text: 'Réunion'
  },
  {
    value: 'ROU',
    text: 'Romania',
    eu: true
  },
  {
    value: 'RUS',
    text: 'Russian Federation'
  },
  {
    value: 'RWA',
    text: 'Rwanda'
  },
  {
    value: 'BLM',
    text: 'Saint Barthélemy'
  },
  {
    value: 'SHN',
    text: 'Saint Helena, Ascension and Tristan da Cunha'
  },
  {
    value: 'KNA',
    text: 'Saint Kitts and Nevis'
  },
  {
    value: 'LCA',
    text: 'Saint Lucia'
  },
  {
    value: 'MAF',
    text: 'Saint Martin (French part)'
  },
  {
    value: 'SPM',
    text: 'Saint Pierre and Miquelon'
  },
  {
    value: 'VCT',
    text: 'Saint Vincent and the Grenadines'
  },
  {
    value: 'WSM',
    text: 'Samoa'
  },
  {
    value: 'SMR',
    text: 'San Marino'
  },
  {
    value: 'STP',
    text: 'Sao Tome and Principe'
  },
  {
    value: 'SAU',
    text: 'Saudi Arabia'
  },
  {
    value: 'SEN',
    text: 'Senegal'
  },
  {
    value: 'SRB',
    text: 'Serbia'
  },
  {
    value: 'SYC',
    text: 'Seychelles'
  },
  {
    value: 'SLE',
    text: 'Sierra Leone'
  },
  {
    value: 'SGP',
    text: 'Singapore'
  },
  {
    value: 'SXM',
    text: 'Sint Maarten (Dutch part)'
  },
  {
    value: 'SVK',
    text: 'Slovakia',
    eu: true
  },
  {
    value: 'SVN',
    text: 'Slovenia',
    eu: true
  },
  {
    value: 'SLB',
    text: 'Solomon Islands'
  },
  {
    value: 'SOM',
    text: 'Somalia'
  },
  {
    value: 'ZAF',
    text: 'South Africa'
  },
  {
    value: 'SGS',
    text: 'South Georgia and the South Sandwich Islands'
  },
  {
    value: 'SSD',
    text: 'South Sudan'
  },
  {
    value: 'ESP',
    text: 'Spain',
    eu: true
  },
  {
    value: 'LKA',
    text: 'Sri Lanka'
  },
  {
    value: 'SDN',
    text: 'Sudan'
  },
  {
    value: 'SUR',
    text: 'Suriname'
  },
  {
    value: 'SJM',
    text: 'Svalbard and Jan Mayen'
  },
  {
    value: 'SWZ',
    text: 'Swaziland'
  },
  {
    value: 'SWE',
    text: 'Sweden',
    eu: true
  },
  {
    value: 'CHE',
    text: 'Switzerland'
  },
  {
    value: 'SYR',
    text: 'Syrian Arab Republic'
  },
  {
    value: 'TWN',
    text: 'Taiwan, Province of China'
  },
  {
    value: 'TJK',
    text: 'Tajikistan'
  },
  {
    value: 'TZA',
    text: 'Tanzania, United Republic of'
  },
  {
    value: 'THA',
    text: 'Thailand'
  },
  {
    value: 'TLS',
    text: 'Timor-Leste'
  },
  {
    value: 'TGO',
    text: 'Togo'
  },
  {
    value: 'TKL',
    text: 'Tokelau'
  },
  {
    value: 'TON',
    text: 'Tonga'
  },
  {
    value: 'TTO',
    text: 'Trinidad and Tobago'
  },
  {
    value: 'TUN',
    text: 'Tunisia'
  },
  {
    value: 'TUR',
    text: 'Turkey'
  },
  {
    value: 'TKM',
    text: 'Turkmenistan'
  },
  {
    value: 'TCA',
    text: 'Turks and Caicos Islands'
  },
  {
    value: 'TUV',
    text: 'Tuvalu'
  },
  {
    value: 'UGA',
    text: 'Uganda'
  },
  {
    value: 'UKR',
    text: 'Ukraine'
  },
  {
    value: 'ARE',
    text: 'United Arab Emirates'
  },
  {
    value: 'GBR',
    text: 'United Kingdom',
    eu: true
  },
  {
    value: 'USA',
    text: 'United States'
  },
  {
    value: 'UMI',
    text: 'United States Minor Outlying Islands'
  },
  {
    value: 'URY',
    text: 'Uruguay'
  },
  {
    value: 'UZB',
    text: 'Uzbekistan'
  },
  {
    value: 'VUT',
    text: 'Vanuatu'
  },
  {
    value: 'VEN',
    text: 'Venezuela, Bolivarian Republic of'
  },
  {
    value: 'VNM',
    text: 'Viet Nam'
  },
  {
    value: 'VGB',
    text: 'Virgin Islands, British'
  },
  {
    value: 'VIR',
    text: 'Virgin Islands, U.S.'
  },
  {
    value: 'WLF',
    text: 'Wallis and Futuna'
  },
  {
    value: 'ESH',
    text: 'Western Sahara'
  },
  {
    value: 'YEM',
    text: 'Yemen'
  },
  {
    value: 'ZMB',
    text: 'Zambia'
  },
  {
    value: 'ZWE',
    text: 'Zimbabwe'
  }
];
